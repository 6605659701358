// CompetitorModal.js

import React, { useState } from 'react';

const CompetitorModal = ({
  isOpen,
  onClose,
  onSubmit,
  addingCompetitor // <-- new prop
}) => {
  const [competitorUrl, setCompetitorUrl] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!competitorUrl.trim()) return;
    onSubmit({ url: competitorUrl.trim() });
    setCompetitorUrl('');
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-70 dark:bg-opacity-90 z-50">
      <div className="bg-white dark:bg-slate-800 border dark:text-white rounded-lg dark:border-slate-700 p-10 w-full max-w-md relative">

        {/* Close Button */}
        <button
          onClick={onClose}
          className="absolute top-3 right-3 text-gray-500 hover:text-gray-700 hidden"
          aria-label="Close modal"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path 
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>

        <h2 className="text-2xl mb-4 flex items-center">
          <svg
            className="mr-2"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
            fill="none"
          >
            <path
              d="M10 3.32038C6.75053 2.8233 3.67361 2.85605 2.76477 3.76477C0.59286 5.93695 3.42489 20.494 6.53497 20.1912C8.24124 20.0189 9.43225 17.2787 10.8342 16.4228C11.3865 16.0856 11.8761 16.4281 12.222 16.8784L15.569 21.2348C16.1521 21.9939 16.5628 22.2198 17.4421 21.7696C18.7939 21.0775 20.0785 19.7911 20.7696 18.4418C21.2198 17.5628 20.9939 17.152 20.235 16.569L18 14.8519"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M15 7H19M17 9V5M22 7C22 9.76142 19.7614 12 17 12C14.2386 12 12 9.76142 12 7C12 4.23858 14.2386 2 17 2C19.7614 2 22 4.23858 22 7Z"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          Add Competitor
        </h2>

        <form onSubmit={handleSubmit}>
          <label className="block text-slate-700 dark:text-slate-500 mb-2 text-sm font-medium">
            Competitor URL <span className="text-red-500">*</span>
          </label>
          <input
            type="url"
            value={competitorUrl}
            onChange={(e) => setCompetitorUrl(e.target.value)}
            required
            className="w-full px-3 py-3 bg-slate-50 dark:bg-slate-700 rounded-md focus:outline-none"
            placeholder="https://competitor.com"
          />

          <div className="flex justify-end space-x-2 mt-4">
            <button
              onClick={onClose}
              type="button"
              className="px-4 py-2 text-slate-600 rounded-lg dark:hover:text-slate-400 hover:text-gray-800 dark:text-slate-700 focus:outline-none focus:ring-2 focus:ring-gray-300"
            >
              Cancel
            </button>

            <button
              type="submit"
              disabled={addingCompetitor} // disable while loading
              className="flex items-center px-5 py-2 bg-yellow-500 text-white rounded-lg hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              {addingCompetitor ? (
                <>
                <i class='bx bx-circle bx-burst bx-rotate-270 bx-fw'></i>Adding...
                </>
              ) : (
                <>
                  Submit
                  <svg
                    className="ml-2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    fill="none"
                  >
                    <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="1.5" />
                    <path
                      d="M10.5 8C10.5 8 13.5 10.946 13.5 12C13.5 13.0541 10.5 16 10.5 16"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CompetitorModal;
