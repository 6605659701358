// ProjectsPage.js

import React, { useState, useEffect } from 'react';
import KeywordModal from './components/KeywordModal';
import CompetitorModal from './components/CompetitorModal';
import ConfirmationModal from './components/ConfirmationModal';
import SettingsButton from './components/SettingsButton';
import LoadingScreen from './components/LoadingScreen';
import { FlagIcon } from 'react-flag-kit';
import { Link, useNavigate } from 'react-router-dom';

const ProjectsPage = () => {
  // ------------------------------
  // State Hooks
  // ------------------------------
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [selectedWebsite, setSelectedWebsite] = useState(null);
  const navigate = useNavigate();

  const [showKeywordModal, setShowKeywordModal] = useState(false);
  const [showCompetitorModal, setShowCompetitorModal] = useState(false);

  const [showRemoveKeywordModal, setShowRemoveKeywordModal] = useState(false);
  const [keywordToRemove, setKeywordToRemove] = useState(null);

  const [showRemoveCompetitorModal, setShowRemoveCompetitorModal] = useState(false);
  const [competitorToRemove, setCompetitorToRemove] = useState(null);

  const [showRemoveProjectModal, setShowRemoveProjectModal] = useState(false);
  const [projectToRemove, setProjectToRemove] = useState(null);

  // Tracks which project's details are expanded
  const [expandedProjectId, setExpandedProjectId] = useState(null);

  // User Plan State
  const [userPlan, setUserPlan] = useState(null);

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const projectsPerPage = 10;

  // States for "Add" button loading
  const [addingKeyword, setAddingKeyword] = useState(false);
  const [addingCompetitor, setAddingCompetitor] = useState(false);

  // States for "Remove" button loading/spinner
  const [removingProject, setRemovingProject] = useState(false);
  const [removingKeyword, setRemovingKeyword] = useState(false);
  const [removingCompetitor, setRemovingCompetitor] = useState(false);

  // Cache-buster
  const timestamp = new Date().getTime();

  // ------------------------------
  // On mount: Fetch user plan & projects
  // ------------------------------
  useEffect(() => {
    fetchUserPlan();
    fetchProjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ------------------------------
  // Fetch user plan
  // ------------------------------
  const fetchUserPlan = async () => {
    try {
      setLoading(true);
      setError('');

      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('User authentication token is missing.');
      }

      const response = await fetch(
        `https://www.pixelliongroup.com/seotic/get_user_plan.php?_=${timestamp}`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ token }),
        }
      );

      const data = await response.json();
      if (!response.ok || data.status !== 'success') {
        throw new Error(data.message || 'Failed to fetch user plan.');
      }

      setUserPlan(data.plan);
    } catch (error) {
      setError(error.message || 'An error occurred while fetching user plan.');
      console.error('Error fetching user plan:', error);
    } finally {
      setLoading(false);
    }
  };

  // ------------------------------
  // Fetch projects
  // ------------------------------
  const fetchProjects = async () => {
    try {
      setLoading(true);
      setError('');

      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('User authentication token is missing.');
      }

      const response = await fetch(
        `https://www.pixelliongroup.com/seotic/get_projects.php?_=${timestamp}`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ token }),
        }
      );

      const resData = await response.json();
      if (!response.ok || resData.status !== 'success') {
        throw new Error(resData.message || 'Failed to fetch projects.');
      }

      // Normalize data
      const normalizedProjects = resData.projects.map((project) => ({
        ...project,
        keywords: project.keywords.map((kw) => ({
          ...kw,
          website_gl: kw.website_gl ? kw.website_gl.trim().toUpperCase() : 'US',
          website_hl: kw.website_hl ? kw.website_hl.trim().toLowerCase() : 'en',
        })),
      }));

      setProjects(normalizedProjects);
    } catch (error) {
      setError(error.message || 'An error occurred while fetching projects.');
      console.error('Error fetching projects:', error);
    } finally {
      setLoading(false);
    }
  };

  // ------------------------------
  // Add Keyword
  // ------------------------------
  const handleAddKeyword = async (keywordObj) => {
    try {
      setAddingKeyword(true);
      setLoading(true);

      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('User authentication token is missing.');
      }

      const project = projects.find((p) => p.id === expandedProjectId);
      if (!project) {
        throw new Error('Selected project not found.');
      }

      // Check plan limit
      if (userPlan && project.keywords.length >= userPlan.keywords_limit) {
        throw new Error(
          'You have reached the maximum number of keywords allowed for your plan.'
        );
      }

      const payload = {
        token,
        website_id: project.id,
        keyword: keywordObj.keyword,
        gl: keywordObj.gl,
        hl: keywordObj.hl,
      };

      const response = await fetch(
        `https://www.pixelliongroup.com/seotic/add_keyword.php?_=${timestamp}`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(payload),
        }
      );

      const resData = await response.json();
      if (!response.ok || resData.status !== 'success') {
        throw new Error(resData.message || 'Failed to add keyword.');
      }

      setSuccess('');
      await fetchProjects();
      setShowKeywordModal(false);
    } catch (error) {
      setError(error.message || 'An error occurred while adding the keyword.');
      console.error('Error adding keyword:', error);
    } finally {
      setAddingKeyword(false);
      setLoading(false);
    }
  };

  // ------------------------------
  // Add Competitor
  // ------------------------------
  const handleAddCompetitor = async (competitorObj) => {
    try {
      setAddingCompetitor(true);
      setLoading(true);

      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('User authentication token is missing.');
      }

      const project = projects.find((p) => p.id === expandedProjectId);
      if (!project) {
        throw new Error('Selected project not found.');
      }

      // Check plan limit
      if (userPlan && project.competitors.length >= userPlan.competitor_limit) {
        throw new Error(
          'You have reached the maximum number of competitors allowed for your plan.'
        );
      }

      const payload = {
        token,
        website_id: project.id,
        url: competitorObj.url,
      };

      const response = await fetch(
        `https://www.pixelliongroup.com/seotic/add_competitors.php?_=${timestamp}`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(payload),
        }
      );

      const resData = await response.json();
      if (!response.ok || resData.status !== 'success') {
        throw new Error(resData.message || 'Failed to add competitor.');
      }

      setSuccess('');
      await fetchProjects();
      setShowCompetitorModal(false);
    } catch (error) {
      setError(error.message || 'An error occurred while adding the competitor.');
      console.error('Error adding competitor:', error);
    } finally {
      setAddingCompetitor(false);
      setLoading(false);
    }
  };

  // ------------------------------
  // Remove Keyword
  // ------------------------------
  const handleRemoveKeyword = async () => {
    try {
      setRemovingKeyword(true);
      setLoading(true);

      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('User authentication token is missing.');
      }

      const project = projects.find((p) => p.id === expandedProjectId);
      if (!project) {
        throw new Error('Selected project not found.');
      }

      const payload = { token, keyword_id: keywordToRemove.id };
      const response = await fetch(
        `https://www.pixelliongroup.com/seotic/remove_keyword.php?_=${timestamp}`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(payload),
        }
      );

      const resData = await response.json();
      if (!response.ok || resData.status !== 'success') {
        throw new Error(resData.message || 'Failed to remove keyword.');
      }

      setSuccess('');
      await fetchProjects();
      setShowRemoveKeywordModal(false);
      setKeywordToRemove(null);
    } catch (error) {
      setError(
        error.message || 'An error occurred while removing the keyword.'
      );
      console.error('Error removing keyword:', error);
    } finally {
      setRemovingKeyword(false);
      setLoading(false);
    }
  };

  // ------------------------------
  // Remove Competitor
  // ------------------------------
  const handleRemoveCompetitor = async () => {
    try {
      setRemovingCompetitor(true);
      setLoading(true);

      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('User authentication token is missing.');
      }

      const project = projects.find((p) => p.id === expandedProjectId);
      if (!project) {
        throw new Error('Selected project not found.');
      }

      const payload = { token, competitor_id: competitorToRemove.id };
      const response = await fetch(
        `https://www.pixelliongroup.com/seotic/remove_competitors.php?_=${timestamp}`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(payload),
        }
      );

      const resData = await response.json();
      if (!response.ok || resData.status !== 'success') {
        throw new Error(resData.message || 'Failed to remove competitor.');
      }

      setSuccess('');
      await fetchProjects();
      setShowRemoveCompetitorModal(false);
      setCompetitorToRemove(null);
    } catch (error) {
      setError(
        error.message || 'An error occurred while removing the competitor.'
      );
      console.error('Error removing competitor:', error);
    } finally {
      setRemovingCompetitor(false);
      setLoading(false);
    }
  };

  // ------------------------------
  // Remove Project
  // ------------------------------
  const handleRemoveProject = async () => {
    try {
      setRemovingProject(true);
      setLoading(true);

      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('User authentication token is missing.');
      }

      const payload = { token, website_id: projectToRemove.id };
      const response = await fetch(
        `https://www.pixelliongroup.com/seotic/remove_project.php?_=${timestamp}`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(payload),
        }
      );

      const resData = await response.json();
      if (!response.ok || resData.status !== 'success') {
        throw new Error(resData.message || 'Failed to remove project.');
      }

      setSuccess('');
      await fetchProjects();
      setShowRemoveProjectModal(false);
      setProjectToRemove(null);

      if (projectToRemove.id === expandedProjectId) {
        setExpandedProjectId(null);
      }
    } catch (error) {
      setError(error.message || 'An error occurred while removing the project.');
      console.error('Error removing project:', error);
    } finally {
      setRemovingProject(false);
      setLoading(false);
    }
  };

  // ------------------------------
  // Select website & launch dashboard
  // ------------------------------
  const handleWebsiteSelect = (website) => {
    localStorage.setItem(
      'project',
      JSON.stringify({ id: website.id, url: website.url })
    );
    setSelectedWebsite(website);
    window.location.href = `/dashboard?selectedWebsite=${encodeURIComponent(
      JSON.stringify(website)
    )}`;
  };

  // ------------------------------
  // Toggle expand project details
  // ------------------------------
  const toggleProjectDetails = (projectId) => {
    if (expandedProjectId === projectId) {
      setExpandedProjectId(null);
    } else {
      setExpandedProjectId(projectId);
    }
  };

  // ------------------------------
  // Pagination
  // ------------------------------
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const indexOfLastProject = currentPage * projectsPerPage;
  const indexOfFirstProject = indexOfLastProject - projectsPerPage;
  const currentProjects = projects.slice(indexOfFirstProject, indexOfLastProject);

  // ------------------------------
  // Helpers
  // ------------------------------
  const getFaviconUrl = (websiteUrl) => {
    try {
      const url = new URL(websiteUrl);
      return `https://icon.horse/icon/${url.hostname}`;
    } catch (e) {
      return 'https://cdn-icons-png.flaticon.com/512/5339/5339181.png';
    }
  };

  // If loading the initial data & no projects yet
  if (loading && projects.length === 0) {
    return <LoadingScreen />;
  }

  return (
    <div className="flex flex-col items-center min-h-screen dark:bg-slate-800 bg-slate-100 p-10 pt-20 mx-auto container">
      {/* Success Message */}
      {success && (
        <div className="flex items-center justify-center my-4">
          <p className="w-96 mt-4 mb-2 text-center text-green-500">{success}</p>
        </div>
      )}

      {/* Error Message */}
      {error && (
        <div className="flex items-center justify-center my-4 text-center dark:text-white">
          <p className="w-96 mt-4 mb-10">
            <i className="bx bx-error-circle bx-rotate-180 bx-lg text-red-500"></i>
            <h3 className="text-4xl mb-2">Oh snap!</h3>
            Something went wrong, please try again later.
            <p className="text-sm text-slate-500 mt-5">{error}</p>
          </p>
        </div>
      )}

      {/* Projects Table */}
      <div className="w-full bg-white dark:bg-slate-700 rounded-lg border-slate-200 dark:border-slate-600 border dark:text-white">
        <div className="w-full overflow-x-auto">
          <div className="flex justify-between items-center px-10 py-10">
            <div>
              <h2 className="text-xl flex items-center">
                <svg
                  className="mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  fill="none"
                >
                  <path
                    d="M8 7H16.75C18.8567 7 19.91 7 20.6667 7.50559C20.9943 7.72447 21.2755 8.00572 21.4944 8.33329C22 9.08996 22 10.1433 22 12.25C22 15.7612 22 17.5167 21.1573 18.7779C20.7926 19.3238 20.3238 19.7926 19.7779 20.1573C18.5167 21 16.7612 21 13.25 21H12C7.28595 21 4.92893 21 3.46447 19.5355C2 18.0711 2 15.714 2 11V7.94427C2 6.1278 2 5.21956 2.38032 4.53806C2.65142 4.05227 3.05227 3.65142 3.53806 3.38032C4.21956 3 5.1278 3 6.94427 3C8.10802 3 8.6899 3 9.19926 3.19101C10.3622 3.62712 10.8418 4.68358 11.3666 5.73313L12 7"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                  />
                </svg>
                Projects
              </h2>
            </div>

            {/* Add Project Button */}
            {userPlan && projects.length >= userPlan.projects_limit ? (
              <button
                disabled
                className="p-1 text-slate-500 border border-slate-200 dark:border-slate-500 rounded-lg cursor-not-allowed"
                title="Project limit reached"
              >
                <i className="bx bx-plus-circle bx-fw"></i>
              </button>
            ) : (
              <Link
                to="/addproject"
                className="p-1 text-slate-500 border border-slate-200 dark:border-slate-500 rounded-lg dark:hover:text-slate-300 hover:text-slate-900"
              >
                <button>
                  <i className="bx bx-plus-circle bx-fw"></i>
                </button>
              </Link>
            )}
          </div>

          <table className="min-w-full">
            <thead>
              <tr className="bg-slate-50 dark:bg-slate-600 text-sm">
                <th className="py-5 px-10 font-normal dark:text-slate-400 text-slate-500 text-left">
                  Projects
                </th>
                <th></th>
                <th className="py-5 px-10 font-normal dark:text-slate-400 text-slate-500 text-left">
                  Description
                </th>
                <th className="py-5 px-10 font-normal dark:text-slate-400 text-slate-500 text-left">
                  Keywords
                </th>
                <th className="py-5 px-10 font-normal dark:text-slate-400 text-slate-500 text-left">
                  Competitors
                </th>
                <th className="py-5 px-10 font-normal dark:text-slate-400 text-slate-500 text-left">
                  Started
                </th>
                <th className="py-5 px-10 font-normal dark:text-slate-400 text-slate-500 text-left">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {currentProjects.length === 0 && !loading ? (
                <tr>
                  <td colSpan="7" className="py-4 px-6 text-center text-gray-500">
                    No projects found. Add a new project to get started.
                  </td>
                </tr>
              ) : (
                currentProjects.map((project) => (
                  <React.Fragment key={project.id}>
                    <tr className="border-y border-slate-100 dark:border-slate-600 hover:bg-yellow-50 dark:hover:bg-slate-500">
                      {/* Project & Launch */}
                      <td className="py-5 px-10 flex items-center">
                        <button
                          className="flex items-center no-underline"
                          onClick={() => handleWebsiteSelect(project)}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={getFaviconUrl(project.url)}
                            alt="Selected Website Favicon"
                            className="w-5 h-5 mr-2"
                            onError={(e) => {
                              e.target.src =
                                'https://cdn-icons-png.flaticon.com/512/5339/5339181.png';
                            }}
                          />
                          {project.url.replace(/(https?:\/\/)?(www\.)?/, '')}
                        </button>
                      </td>
                      <td>
                        <button
                          onClick={() => handleWebsiteSelect(project)}
                          className="px-3 py-1 text-sm rounded bg-yellow-400 text-white ml-10"
                        >
                          Launch
                        </button>
                      </td>

                      {/* Description */}
                      <td className="py-5 px-10 text-sm dark:text-slate-400 text-slate-700 w-full">
                        {project.description || '-'}
                      </td>

                      {/* Keywords & Competitors counts */}
                      <td className="py-5 px-10 text-center text-sm dark:text-slate-400 text-slate-700">
                        {project.keywords.length}
                      </td>
                      <td className="py-5 px-10 text-center text-sm dark:text-slate-400 text-slate-700">
                        {project.competitors.length}
                      </td>

                      {/* Created At */}
                      <td className="py-5 px-10 text-center text-sm dark:text-slate-400 text-slate-700">
                        {new Date(project.created_at).toLocaleDateString()}
                      </td>

                      {/* Actions */}
                      <td className="py-5 px-10 text-center flex justify-center space-x-2">
                        <SettingsButton
                          onSettingsClick={() => toggleProjectDetails(project.id)}
                        />
                        <button
                          onClick={() => {
                            setProjectToRemove(project);
                            setShowRemoveProjectModal(true);
                          }}
                          className="p-1 text-slate-300 bg-slate-100 dark:bg-slate-600 rounded-lg hover:bg-red-500 hover:text-white focus:outline-none"
                        >
                          <i className="bx bx-trash bx-fw"></i>
                        </button>
                      </td>
                    </tr>

                    {/* Expanded: Keywords & Competitors */}
                    {expandedProjectId === project.id && (
                      <tr>
                        <td colSpan="7" className="w-full bg-white dark:bg-slate-600">
                          <div className="flex flex-col md:flex-row w-full">
                            {/* Keywords Section */}
                            <div className="w-full md:w-1/2 px-10 py-5 border-r border-slate-100 dark:border-slate-500">
                              <div className="flex justify-between items-center mb-4">
                                <h3 className="text-xl ">
                                  <i className="bx bx-text bx-fw"></i>Keywords
                                </h3>
                                {userPlan && project.keywords.length >= userPlan.keywords_limit ? (
                                  <button
                                    disabled
                                    className="p-1 text-slate-500 border border-slate-200 dark:border-slate-500 rounded-lg cursor-not-allowed"
                                    title="Keyword limit reached"
                                  >
                                    <i className="bx bx-plus-circle bx-fw"></i>
                                  </button>
                                ) : (
                                  <button
                                    onClick={() => setShowKeywordModal(true)}
                                    className="p-1 text-slate-500 border border-slate-200 dark:border-slate-500 rounded-lg dark:hover:text-slate-300 hover:text-slate-900"
                                  >
                                    <i className="bx bx-plus-circle bx-fw"></i>
                                  </button>
                                )}
                              </div>

                              {project.keywords.length === 0 ? (
                                <p className="text-gray-500">No keywords added yet.</p>
                              ) : (
                                <div className="overflow-x-auto border border-slate-100 dark:border-slate-500 rounded-md">
                                  <table className="min-w-full">
                                    <thead>
                                      <tr className="bg-slate-50 dark:bg-slate-500 text-sm border-b border-slate-100 dark:border-slate-500 text-slate-500 dark:text-slate-300">
                                        <th className="py-3 px-4 text-left font-normal">
                                          Keyword
                                        </th>
                                        <th className="py-3 px-4 text-center font-normal">
                                          Location
                                        </th>
                                        <th className="py-3 px-4 text-center font-normal">
                                          Language
                                        </th>
                                        <th className="py-3 px-4 text-left font-normal"></th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {project.keywords.map((kw) => (
                                        <tr
                                          key={kw.id}
                                          className="border-b border-slate-100 dark:border-slate-500 hover:bg-yellow-50 dark:hover:bg-slate-500"
                                        >
                                          <td className="py-3 px-4 text-sm">
                                            {kw.keyword || 'N/A'}
                                          </td>
                                          <td className="py-3 px-4 flex items-center justify-center text-slate-500 dark:text-slate-400 text-xs">
                                            {typeof kw.website_gl === 'string' &&
                                            kw.website_gl.trim() !== '' ? (
                                              <>
                                                <FlagIcon
                                                  code={kw.website_gl.trim().toUpperCase()}
                                                  size={16}
                                                  className="mr-2"
                                                />
                                                {kw.website_gl.trim().toUpperCase()}
                                              </>
                                            ) : (
                                              <>
                                                <span className="mr-2">N/A</span>
                                                N/A
                                              </>
                                            )}
                                          </td>
                                          <td className="py-3 px-4 text-center dark:text-slate-400 text-slate-500 text-xs">
                                            {kw.website_hl || 'N/A'}
                                          </td>
                                          <td className="py-3 px-4 text-right text-sm">
                                            <button
                                              onClick={() => {
                                                setKeywordToRemove(kw);
                                                setShowRemoveKeywordModal(true);
                                              }}
                                              className="text-red-500 hover:text-red-700"
                                            >
                                              <i className="bx bx-trash bx-fw"></i>
                                            </button>
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                  <p className="text-xs text-slate-500 dark:text-slate-400 px-5 py-2">
                                    Keywords: {project.keywords.length} /{' '}
                                    {userPlan ? userPlan.keywords_limit : '...'}
                                  </p>
                                </div>
                              )}
                            </div>

                            {/* Competitors Section */}
                            <div className="w-full md:w-1/2 px-10 py-5">
                              <div className="flex justify-between items-center mb-4">
                                <h3 className="text-lg">
                                  <i className="bx bx-windows bx-fw"></i>
                                  Competitors
                                </h3>
                                {userPlan &&
                                project.competitors.length >= userPlan.competitor_limit ? (
                                  <button
                                    disabled
                                    className="p-1 text-slate-500 border border-slate-200 dark:border-slate-500 rounded-lg cursor-not-allowed"
                                    title="Competitors limit reached"
                                  >
                                    <i className="bx bx-plus-circle bx-fw"></i>
                                  </button>
                                ) : (
                                  <button
                                    onClick={() => setShowCompetitorModal(true)}
                                    className="p-1 text-slate-500 border border-slate-200 dark:border-slate-500 rounded-lg dark:hover:text-slate-300 hover:text-slate-900"
                                  >
                                    <i className="bx bx-plus-circle bx-fw"></i>
                                  </button>
                                )}
                              </div>

                              {project.competitors.length === 0 ? (
                                <p className="text-gray-500">No competitors added yet.</p>
                              ) : (
                                <div className="overflow-x-auto border border-slate-100 dark:border-slate-500 rounded-md">
                                  <table className="min-w-full">
                                    <thead>
                                      <tr className="bg-slate-50 dark:bg-slate-500 text-sm border-b border-slate-100 dark:border-slate-500">
                                        <th className="py-3 px-4 text-left font-normal text-slate-500 dark:text-slate-300">
                                          Competitor URL
                                        </th>
                                        <th className=""></th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {project.competitors.map((comp) => (
                                        <tr
                                          key={comp.id}
                                          className="border-b border-slate-100 dark:border-slate-500 dark:hover:bg-slate-500 hover:bg-yellow-50 text-sm"
                                        >
                                          <td className="py-3 px-4">
                                            <a
                                              href={comp.url}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              className="text-sm"
                                            >
                                              {comp.url}
                                            </a>
                                          </td>
                                          <td className="py-3 px-4 text-right text-sm">
                                            <button
                                              onClick={() => {
                                                setCompetitorToRemove(comp);
                                                setShowRemoveCompetitorModal(true);
                                              }}
                                              className="text-red-500 hover:text-red-700"
                                            >
                                              <i className="bx bx-trash bx-fw"></i>
                                            </button>
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                  <p className="text-xs text-slate-500 dark:text-slate-400 px-5 py-2">
                                    Competitors: {project.competitors.length} /{' '}
                                    {userPlan ? userPlan.competitor_limit : '...'}
                                  </p>
                                </div>
                              )}
                            </div>
                          </div>
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                ))
              )}
            </tbody>
          </table>

          {/* Projects count */}
          <p className="px-10 py-5 text-xs uppercase text-slate-500 dark:text-slate-400 -mt-[1px] border-t border-slate-100 dark:border-slate-600">
            Projects: {projects.length} / {userPlan ? userPlan.projects_limit : '...'}
          </p>
        </div>

        {/* Pagination */}
        {projects.length > projectsPerPage && (
          <div className="flex justify-center mt-4">
            <div className="flex space-x-2">
              {Array.from({ length: Math.ceil(projects.length / projectsPerPage) }, (_, i) => (
                <button
                  key={i + 1}
                  onClick={() => paginate(i + 1)}
                  className={`px-4 py-2 rounded-lg ${
                    currentPage === i + 1
                      ? 'bg-yellow-500 text-white'
                      : 'bg-slate-100 text-slate-600 hover:bg-slate-200'
                  }`}
                >
                  {i + 1}
                </button>
              ))}
            </div>
          </div>
        )}
      </div>

      {/* Remove Project Confirmation Modal */}
      <ConfirmationModal
        isOpen={showRemoveProjectModal}
        onClose={() => setShowRemoveProjectModal(false)}
        onConfirm={handleRemoveProject}
        title="Remove Project"
        message={
          <>
            Are you sure you want to delete the project{' '}
            <span className="bg-red-400 rounded px-2 py-1 text-white">
              {projectToRemove?.url}
            </span>?{' '}
            <p className="my-5">
              This action is irreversible and all related data will be permanently lost.
            </p>
          </>
        }
        isRemoving={removingProject}  // <-- pass removingProject to show spinner in modal
      />

      {/* KeywordModal for Adding Keywords */}
      <KeywordModal
        isOpen={showKeywordModal}
        onClose={() => setShowKeywordModal(false)}
        onSubmit={handleAddKeyword}
        addingKeyword={addingKeyword} // Pass the loading state here
      />

      {/* CompetitorModal for Adding Competitors */}
      <CompetitorModal
        isOpen={showCompetitorModal}
        onClose={() => setShowCompetitorModal(false)}
        onSubmit={handleAddCompetitor}
        addingCompetitor={addingCompetitor} // Pass the loading state here
      />

      {/* Remove Keyword Confirmation Modal */}
      <ConfirmationModal
        isOpen={showRemoveKeywordModal}
        onClose={() => setShowRemoveKeywordModal(false)}
        onConfirm={handleRemoveKeyword}
        title="Remove Keyword"
        message={
          <>
            Are you sure you want to delete the keyword{' '}
            <span className="bg-red-400 rounded px-2 py-1 text-white">
              {keywordToRemove?.keyword}
            </span>? 
            <p className="my-5">This action cannot be undone.</p>
          </>
        }
        isRemoving={removingKeyword} // <-- pass removingKeyword to show spinner
      />

      {/* Remove Competitor Confirmation Modal */}
      <ConfirmationModal
        isOpen={showRemoveCompetitorModal}
        onClose={() => setShowRemoveCompetitorModal(false)}
        onConfirm={handleRemoveCompetitor}
        title="Remove Competitor"
        message={
          <>
            Are you sure you want to delete the competitor{' '}
            <span className="bg-red-400 rounded px-2 py-1 text-white">
              {competitorToRemove?.url}
            </span>? 
            <p className="my-5">This action cannot be undone.</p>
          </>
        }
        isRemoving={removingCompetitor} // <-- pass removingCompetitor to show spinner
      />
    </div>
  );
};

export default ProjectsPage;
