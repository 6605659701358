// src/components/Dashboard.js

import React, { useState, useEffect, useRef } from 'react';
import TimeAgo from './components/TimeAgo';
import Tooltip from './components/Tooltip';
import LoadingScreen from './components/LoadingScreen';
import { Link, useNavigate } from 'react-router-dom';
import AISuggestions from './AISuggestions';
import Slider from './components/Slider';
import Feedback from './components/Feedback';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'boxicons/css/boxicons.min.css';
import TrafficSection from './components/dashboard/TrafficSection';
import KeywordsSection from './components/dashboard/KeywordsSection';
import SuggestionsSection from './components/dashboard/SuggestionsSection';
import FeedbackSection from './components/dashboard/FeedbackSection';

// Define which sections to render in the dashboard
const dashboardSections = [
  { id: 'traffic', component: TrafficSection },
  { id: 'suggestions', component: SuggestionsSection },
  { id: 'feedback', component: FeedbackSection },
];

const Dashboard = () => {
  // ------------------------------
  // State Hooks
  // ------------------------------
  const [isLoading, setIsLoading] = useState(true);
  const [plan, setPlan] = useState(null);
  const [websites, setWebsites] = useState([]);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  // SEO/Backlink/Audit Scores
  const [backlinks, setBacklinks] = useState(0);
  const [performanceScore, setPerformanceScore] = useState(0);
  const [accessibilityScore, setAccessibilityScore] = useState(0);
  const [seoAuditScore, setSeoAuditScore] = useState(0);
  const [top1Count, setTop1Count] = useState(0);
  const [top3Count, setTop3Count] = useState(0);
  const [top10Count, setTop10Count] = useState(0);
  const [bounceRate, setBounceRate] = useState(null);

  // AI Limit
  const [aiLimit, setAiLimit] = useState(0);

  // Traffic Data
  const [selectedTab, setSelectedTab] = useState(30);
  const [trafficData, setTrafficData] = useState({
    currentTraffic: 0,
    oneTraffic: 0,
    twoTraffic: 0,
    bounceRate: 0,
    socialRate: 0,
    paidRate: 0,
    reffRate: 0,
    mailRate: 0,
    directRate: 0,
    otherRate: 0,
  });

  // Security Data (Modal)
  const [securityData, setSecurityData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Loading & pagination for security
  const [securityLoading, setSecurityLoading] = useState(false);
  const [blacklistPage, setBlacklistPage] = useState(1);

  // Timestamp to bust cache
  const timestamp = new Date().getTime();

  // JWT & Project Data from localStorage
  const token = localStorage.getItem('token');
  const projectData = JSON.parse(localStorage.getItem('project'));
  const website_id = projectData ? projectData.id : null;
  const projectUrl = projectData ? projectData.url : null;
  const url = projectUrl ? new URL(projectUrl).hostname : '';

  // For Navigation
  const navigate = useNavigate();

  // ------------------------------
  // JWT Decode Helper
  // ------------------------------
  const decodeToken = (token) => {
    try {
      const payload = JSON.parse(atob(token.split('.')[1]));
      return payload;
    } catch {
      return null;
    }
  };

  // ------------------------------
  // Dashboard Data Fetch
  // ------------------------------
  const [maliciousCount, setMaliciousCount] = useState(0);

  useEffect(() => {
    if (securityData) {
      const malicious = securityData.malicious?.detections || 0;
      setMaliciousCount(malicious);
    }
  }, [securityData]);

  const fetchedWebsites = useRef(new Set());

  // Trigger silent security data fetch for each website after main data is loaded
  useEffect(() => {
    if (!isLoading && websites.length > 0) {
      websites.forEach((site) => {
        if (!fetchedWebsites.current.has(site.url)) {
          fetchedWebsites.current.add(site.url);
          fetchSecurityDataSilent(site.url);
        }
      });
    }
  }, [isLoading, websites]);

  useEffect(() => {
    const fetchDashboardData = async () => {
      if (!token) {
        setError('Authorization token not found.');
        setIsLoading(false);
        return;
      }
      if (!projectData || !website_id) {
        setError('No project selected. Please choose a project.');
        setIsLoading(false);
        return;
      }

      // Decode the token to retrieve user ID
      const decoded = decodeToken(token);
      const user_id = decoded?.data?.id;
      if (!user_id) {
        setError('Invalid token: User ID not found.');
        setIsLoading(false);
        return;
      }

      try {
        const response = await fetch(
          `https://www.pixelliongroup.com/seotic/dashboard.php?_=${timestamp}`,
          {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ token, website_id }),
          }
        );

        const data = await response.json();
        console.log('Dashboard Data:', data);

        if (response.ok && data.success) {
          setPlan(data.data.plan);
          setWebsites(data.data.websites || []);
          setSuccess('');

          // If there's at least one website, set initial UI data
          if (data.data.websites && data.data.websites.length > 0) {
            const website = data.data.websites[0];
            console.log('Selected Website:', website);

            // Backlinks
            const totalBacklinks = website.backlinks?.total_backlinks;
            setBacklinks(totalBacklinks ?? 0);

            // Audit Scores
            const performance_s = website.audit?.performance_s;
            setPerformanceScore(performance_s ?? 0);

            const accessibility_s = website.audit?.accessibility_s;
            setAccessibilityScore(accessibility_s ?? 0);

            const seo_s = website.audit?.seo_s;
            setSeoAuditScore(seo_s ?? 0);

            // Keyword Rankings
            const top_1_count = website.keywords?.top_1_count;
            setTop1Count(top_1_count ?? 0);

            const top_3_count = website.keywords?.top_3_count;
            setTop3Count(top_3_count ?? 0);

            const top_10_count = website.keywords?.top_10_count;
            setTop10Count(top_10_count ?? 0);

            // Bounce Rate
            const bounceRateValue = website.bounce_rate;
            setBounceRate(bounceRateValue);

            // Plan Data
            const planData = data.data.plan;
            if (planData) {
              setAiLimit(planData.ai_limit);
            }
          }
        } else {
          setError(data.message || 'Failed to fetch dashboard data.');
        }
      } catch (err) {
        console.error('Fetch Error:', err);
        setError('An error occurred while fetching dashboard data.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchDashboardData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ------------------------------
  // Onboard Redirection
  // ------------------------------
  useEffect(() => {
    if (!isLoading && websites.length > 0) {
      const shouldRedirect = websites.some(
        (w) => w.keywords?.last_update_date === null || w.audit?.audit_date === null
      );
      if (shouldRedirect) {
        navigate('/onboard');
      }
    }
  }, [websites, isLoading, navigate]);

  // ------------------------------
  // Traffic Data Fetch
  // ------------------------------
  const updateDashboardUI = (data) => {
    const totalSpecifiedRate =
      data.social_rate +
      data.paid_rate +
      data.reff_rate +
      data.mail_rate +
      data.direct_rate;

    const calculatedOtherRate = Math.max(100 - totalSpecifiedRate, 0);

    setTrafficData({
      currentTraffic: data.current_traffic,
      oneTraffic: data.lastone_traffic,
      twoTraffic: data.lasttwo_traffic,
      bounceRate: data.bounce_rate,
      socialRate: data.social_rate,
      paidRate: data.paid_rate,
      reffRate: data.reff_rate,
      mailRate: data.mail_rate,
      directRate: data.direct_rate,
      otherRate: calculatedOtherRate,
    });

    setBounceRate(data.bounce_rate);
  };

  const getTrafficData = async (websiteUrl) => {
    if (!token) {
      console.error('Authorization token not found.');
      return;
    }

    try {
      const response = await fetch(
        `https://www.pixelliongroup.com/seotic/get_traffic.php?_=${timestamp}`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ website_url: websiteUrl, token }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        if (data.success) {
          console.log('Traffic Data:', data.data);
          updateDashboardUI(data.data);
        } else {
          console.error('Error:', data.message);
        }
      } else {
        console.error('HTTP Error:', response.status);
      }
    } catch (error) {
      console.error('Network Error:', error);
    }
  };

  // If you only want to load traffic data automatically for the selected project:
  useEffect(() => {
    if (projectUrl) {
      getTrafficData(projectUrl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [websites]);

  // ------------------------------
  // Security Data Fetch (Manual Trigger)
  // ------------------------------
  const [securityDataMap, setSecurityDataMap] = useState({});
  const [silentFetching, setSilentFetching] = useState([]);

  const fetchSecurityData = async (websiteUrl) => {
    if (!token) {
      console.error('Authorization token not found.');
      return;
    }

    try {
      setSecurityLoading(true);
      setSecurityData(null);
      setBlacklistPage(1);

      const response = await fetch('https://www.pixelliongroup.com/seotic/get_security.php', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token, url: projectUrl }),
      });

      const data = await response.json();
      if (data.status === 'success') {
        console.log('Security Data:', data.data);

        // Store it in local state for the modal
        setSecurityData(data.data);

        // Merge into websites so "Not Detected %" can update
        setWebsites((prevSites) =>
          prevSites.map((site) => {
            if (site.url === websiteUrl) {
              return {
                ...site,
                blacklist: data.data.blacklist,
                malicious: data.data.malicious,
              };
            }
            return site;
          })
        );

        // Open the modal only after user clicks "View Security Details"
        setIsModalOpen(true);
      } else {
        console.error('Error fetching security data:', data.message);
      }
    } catch (error) {
      console.error('Network Error:', error);
    } finally {
      setSecurityLoading(false);
    }
  };

  // Silent fetch: updates blacklist & malicious but does NOT open the modal
  const fetchSecurityDataSilent = async (websiteUrl) => {
    if (!token) {
      console.error('Authorization token not found.');
      return;
    }

    setSilentFetching((prev) => [...prev, websiteUrl]);
    try {
      const response = await fetch('https://www.pixelliongroup.com/seotic/get_security.php', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token, url: projectUrl }),
      });

      const data = await response.json();
      if (data.status === 'success') {
        console.log('Silent Security Data:', data.data);

        setWebsites((prevSites) =>
          prevSites.map((site) =>
            site.url === websiteUrl
              ? {
                  ...site,
                  blacklist: data.data.blacklist,
                  malicious: data.data.malicious,
                }
              : site
          )
        );
      } else {
        console.error('Error fetching security data:', data.message);
      }
    } catch (error) {
      console.error('Network Error:', error);
    } finally {
      setSilentFetching((prev) => prev.filter((url) => url !== websiteUrl));
    }
  };

  const openModalWithData = (websiteUrl) => {
    const websiteData = websites.find((site) => site.url === websiteUrl);
    if (websiteData) {
      setSecurityData({
        blacklist: websiteData.blacklist,
        malicious: websiteData.malicious,
      });
      setIsModalOpen(true);
    }
  };

  // ------------------------------
  // Slider Items (Example)
  // ------------------------------
  const sliderItems = [
    <div key="1">
      <h2 className="text-xl mb-2">Keywords</h2>
      <p>Keyword rankings help your website get found. Better rankings mean more visitors and more business!</p>
    </div>,
    <div key="2">
      <h2 className="text-xl mb-2">Guest Blogging</h2>
      <p>Write valuable articles for relevant blogs in your niche. Include a natural link back to your website.</p>
    </div>,
  ];

  // Date formatting in tooltips
  const dateOptions = { year: 'numeric', month: 'short', day: 'numeric' };

  // ------------------------------
  // Render
  // ------------------------------
  return (
    <>
      {/* Loading Screen */}
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <div className="flex flex-col items-center dark:bg-slate-800 bg-slate-100 p-10 min-h-screen mx-auto container">
          {/* Error/Success Messages */}
          {error && (
            <div className="bg-red-500 text-white p-4 rounded mb-4 w-full text-center">
              {error}
            </div>
          )}
          {success && (
            <div className="bg-green-500 text-white p-4 rounded mb-4 w-full text-center">
              {success}
            </div>
          )}

          {/* Header Section */}
          <div className="w-full py-10 flex items-center relative">
            <div className="w-full text-2xl text-left dark:text-white text-slate-800 uppercase">
              Dashboard
              <div className="text-sm text-slate-500 mt-2">
                <i className="bx bx-folder bx-fw"></i> project /{' '}
                {url ? url.replace(/^(www\.)?/, '') : 'N/A'}
              </div>
            </div>
          </div>

          {/* Plan Section (Hidden) */}
          {plan && (
            <div className="bg-white rounded-xl p-10 mb-10 shadow hidden">
              <h2 className="text-2xl font-semibold mb-5">Your Plan</h2>
              <div className="grid grid-cols-2 gap-5">
                <div>
                  <p>
                    <strong>Plan ID:</strong> {plan.id}
                  </p>
                  <p>
                    <strong>User ID:</strong> {plan.user_id}
                  </p>
                  <p>
                    <strong>Projects Limit:</strong> {plan.projects_limit}
                  </p>
                  <p>
                    <strong>Keywords Limit:</strong> {plan.keywords_limit}
                  </p>
                  <p>
                    <strong>Blog Limit:</strong> {plan.blog_limit}
                  </p>
                </div>
                <div>
                  <p>
                    <strong>Expiry Date:</strong>{' '}
                    {new Date(plan.expired).toLocaleDateString()}
                  </p>
                  <p>
                    <strong>Plan:</strong> {plan.plan_type}
                  </p>
                  <p>
                    <strong>Is Expired:</strong> {plan.is_expired ? 'Yes' : 'No'}
                  </p>
                </div>
              </div>
            </div>
          )}

          {/* Websites Section */}
          <div className="w-full">
            {websites.length === 0
              ? navigate('/addproject')
              : websites.map((website, index) => {
                  const domainOnly = website.url
                    ? website.url.replace(/^(https?:\/\/)?(www\.)?/, '')
                    : 'N/A';

                  // Compute "Not Detected" percentage from merged blacklist
                  const blacklists = website.blacklist?.blacklists || [];
                  const totalBlacklists = blacklists.length;
                  const notDetectedCount = blacklists.filter((b) => !b.detected).length;
                  const notDetectedPercentage =
                    totalBlacklists > 0
                      ? (notDetectedCount / totalBlacklists) * 100
                      : 0;

                  return (
                    <div key={index} className="mb-10">
                      {/* Basic Website Info */}
                      <div className="w-full grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 gap-5">
                        {/* AI Suggestions Section */}
                        <div className="col-span-1">
                          <AISuggestions
                            backlinks={backlinks}
                            performanceScore={performanceScore}
                            accessibilityScore={accessibilityScore}
                            seoAuditScore={seoAuditScore}
                            top1Count={top1Count}
                            top3Count={top3Count}
                            top10Count={top10Count}
                            bounceRate={bounceRate}
                            aiLimit={aiLimit}
                          />
                        </div>

                        {/* Top Keywords Count */}
                        <div className="bg-white border-slate-200 dark:bg-slate-700 dark:border-slate-600 rounded-lg border p-10">
                          <p className="text-center">
                            <span className="text-slate-500 dark:text-white">
                              Total Keywords
                            </span>
                            <br />
                            <span className="font-bold dark:text-white text-slate-900 text-8xl">
                              {website.keywords?.total_keywords || 0}
                            </span>
                          </p>
                          <div className="flex justify-center w-full text-center">
                            <Link
                              className="mt-5 w-full dark:text-white dark:bg-slate-600 bg-slate-50 rounded-lg hover:bg-yellow-500 hover:text-white px-5 py-3 text-sm"
                              to="/projects"
                            >
                              <i className="bx bx-plus-circle bx-fw"></i>Add Keywords
                            </Link>
                          </div>
                        </div>

                        {/* Top Keywords Details */}
                        <div className="bg-white border-slate-200 dark:bg-slate-700 dark:border-slate-600 rounded-lg border">
                          <p className="px-10 py-5 flex border-b dark:border-slate-600 dark:text-white">
                            <span className="w-full">Top 1 Keyword</span>
                            <span className="text-slate-500 dark:text-white flex justify-end">
                              {website.keywords?.top_1_count || 0}
                            </span>
                          </p>
                          <p className="px-10 py-5 flex border-b dark:border-slate-600 dark:text-white">
                            <span className="w-full">Top 3 Keyword</span>
                            <span className="text-slate-500 dark:text-white flex justify-end">
                              {website.keywords?.top_3_count || 0}
                            </span>
                          </p>
                          <p className="px-10 py-5 flex border-b dark:border-slate-600 dark:text-white">
                            <span className="w-full">Top 10 Keyword</span>
                            <span className="text-slate-500 dark:text-white flex justify-end">
                              {website.keywords?.top_10_count || 0}
                            </span>
                          </p>
                          <p className="px-10 py-5 flex dark:text-white">
                            <span className="w-full">No Rank</span>
                            <span className="text-slate-500 dark:text-white flex justify-end">
                              {website.keywords?.no_rank_keywords_count || 0}
                            </span>
                          </p>
                        </div>

                        {/* Example Hidden Section with Slider */}
                        <div className="bg-white border-slate-200 dark:bg-slate-700 dark:border-slate-600 dark:text-white rounded-lg border p-5 relative pt-10 hidden">
                          <p
                            className="absolute right-5 top-5 text-slate-500 flex w-fit justify-end text-xs"
                            id={`last-audit-${index}`}
                          >
                            <TimeAgo date={website.keywords?.last_update_date} />
                          </p>
                          <Tooltip
                            forid={`last-audit-${index}`}
                            message={
                              website.keywords?.last_update_date
                                ? new Date(
                                    website.keywords.last_update_date
                                  ).toLocaleDateString('en-US', dateOptions)
                                : 'N/A'
                            }
                            position="top"
                          />

                          <Slider items={sliderItems} />
                        </div>

                        {/* Top Competitors Count */}
                        <div className="bg-white border-slate-200 dark:bg-slate-700 dark:border-slate-600 rounded-lg border p-10 flex items-center justify-center">
                          <p className="text-center">
                            <span className="text-slate-500 dark:text-white">
                              Total Competitors
                            </span>
                            <br />
                            <span className="font-bold dark:text-white text-slate-900 text-8xl">
                              {website.competitors?.total_competitors || 0}
                            </span>
                            <div className="flex justify-center w-full text-center">
                              <Link
                                className="mt-5 w-full dark:text-white dark:bg-slate-600 bg-slate-50 rounded-lg hover:bg-yellow-500 hover:text-white px-5 py-3 text-sm"
                                to="/projects"
                              >
                                <i className="bx bx-plus-circle bx-fw"></i>Add Competitor
                              </Link>
                            </div>
                          </p>
                        </div>
                      </div>

                      {/* Security / Malicious / Audit Scores Row */}
                      <div className="w-full grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 gap-5">
                        {/* "Not Detected" Card & Button */}
                        <div className="mt-5 bg-white border-slate-200 dark:bg-slate-700 dark:border-slate-600 rounded-lg border p-10 flex flex-col items-center justify-center">
                          {silentFetching.includes(website.url) ? (
                            // Show spinner during silent fetch
                            <div className="flex flex-col items-center">
                              <i className=" text-slate-500 bx bx-circle bx-burst bx-rotate-270 bx-fw"></i>
                            </div>
                          ) : (
                            // Normal UI
                            <>
                              <p className="text-center mb-5">
                                <span className="text-slate-500 dark:text-white block">
                                  Clean Score
                                </span>
                                <span className="font-bold dark:text-white text-slate-900 text-8xl">
                                  {notDetectedPercentage.toFixed(0)}
                                </span>
                              </p>
                              <div className="flex justify-center w-full text-center">
                                <button
                                  onClick={() => openModalWithData(website.url)}
                                  className="w-full dark:text-white dark:bg-slate-600 bg-slate-50 rounded-lg hover:bg-yellow-500 hover:text-white px-5 py-3 text-sm"
                                >
                                  <i className="bx bx-info-circle bx-fw"></i>Blacklists Details
                                </button>
                              </div>
                            </>
                          )}
                        </div>

                        {/* Malicious Detections Card */}
                        <div className="mt-5 bg-white border-slate-200 dark:bg-slate-700 dark:border-slate-600 rounded-lg border p-10 flex flex-col items-center justify-center">
                          {silentFetching.includes(website.url) ? (
                            <div className="flex flex-col items-center">
                              <i className=" text-slate-500 bx bx-circle bx-burst bx-rotate-270 bx-fw"></i>
                            </div>
                          ) : (
                            <>
                              <p className="text-center mb-5">
                                <span className="text-slate-500 dark:text-white block">
                                  Malicious Detections
                                </span>
                                <span className="font-bold dark:text-white text-slate-900 text-8xl">
                                  {maliciousCount}
                                </span>
                                <p className="text-xs text-slate-500 mt-5 dark:text-slate-200">
                                  malicious threats detected for your domain
                                </p>
                              </p>
                            </>
                          )}
                        </div>

                        {/* 
                          PERFORMANCE & SEO SCORES 
                          (Here is the updated col-span-2 block) 
                        */}
                        <div className="mt-5 bg-white border-slate-200 dark:bg-slate-700 dark:border-slate-600 rounded-lg border p-10 flex flex-col items-center justify-center">
                         

                          {/* Performance Score */}
                          <div className="flex flex-col items-center">
                            <span className="text-slate-500 dark:text-white block">                              Performance Score
                            </span>
                            <span className="font-bold dark:text-white text-slate-900 text-8xl">
                              {performanceScore}
                            </span>
                             <Link
                                className="mt-5 w-full dark:text-white dark:bg-slate-600 bg-slate-50 rounded-lg hover:bg-yellow-500 hover:text-white px-5 py-3 text-sm"
                                to="/audit"
                              >
                                <i className="bx bx-plus-circle bx-fw"></i>Check Audit
                              </Link>

                          </div>

                                                </div>
                        <div className="mt-5 bg-white border-slate-200 dark:bg-slate-700 dark:border-slate-600 rounded-lg border p-10 flex flex-col items-center justify-center">
                     {/* SEO Score */}
                          <div className="flex flex-col items-center">
                            <span className="text-slate-500 dark:text-white block">                              SEO Score
                            </span>
                            <span className="font-bold dark:text-white text-slate-900 text-8xl">
                              {seoAuditScore}
                            </span>
                             <Link
                                className="mt-5 w-full dark:text-white dark:bg-slate-600 bg-slate-50 rounded-lg hover:bg-yellow-500 hover:text-white px-5 py-3 text-sm"
                                to="/audit"
                              >
                                <i className="bx bx-plus-circle bx-fw"></i>Check Audit
                              </Link>

                          </div>
     
</div>
                      </div>

                      {/* Dynamic Sections (Traffic, Suggestions, Feedback) */}
                      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 gap-5 mt-5">
                        {dashboardSections.map((section) => {
                          const SectionComponent = section.component;
                          let sectionProps = {};

                          switch (section.id) {
                            case 'traffic':
                              sectionProps = { trafficData, selectedTab, setSelectedTab };
                              break;
                            case 'suggestions':
                              sectionProps = {
                                suggestionsObj: website.audit?.suggestions
                                  ? JSON.parse(website.audit.suggestions)
                                  : null,
                              };
                              break;
                            case 'feedback':
                              sectionProps = {};
                              break;
                            default:
                              break;
                          }
                          return <SectionComponent key={section.id} {...sectionProps} />;
                        })}
                      </div>
                    </div>
                  );
                })}
          </div>
        </div>
      )}

      {/* Security Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center pt-10 pb-10 bg-black bg-opacity-70 dark:bg-opacity-80 z-50">
          <div className="bg-white dark:bg-slate-700 rounded-lg w-full max-w-3xl shadow-lg relative">
            {/* Close button */}
            <button
              onClick={() => {
                setIsModalOpen(false);
                setSecurityData(null);
              }}
              className="absolute top-4 right-4 text-gray-500 hover:text-slate-700 dark:hover:text-slate-400"
            >
              <i className="bx bx-x text-2xl"></i>
            </button>

            <h2 className="text-2xl dark:text-slate-100 text-slate-800 px-10 pt-10">
              <i className="bx bx-spreadsheet bx-fw"></i> Blacklist Monitor
            </h2>

            {/* Loading Indicator */}
            {securityLoading ? (
              <div className="flex items-center justify-center h-32">
                <span className="text-blue-500 dark:text-blue-300">Loading...</span>
              </div>
            ) : securityData ? (
              <>
                {/* Blacklist Data with numeric pagination */}
                <div className="mb-6">
                  {securityData.blacklist?.blacklists ? (
                    <>
                      {securityData.blacklist && (
                        <div className="mt-2 text-md text-slate-600 dark:text-slate-300 px-10 mb-5">
                          {securityData.blacklist.detections > 0 ? (
                            <p>
                              We’ve identified{' '}
                              <strong>{securityData.blacklist.detections}</strong> blacklists for
                              your domain.{' '}
                              <a
                                href="https://www.seotic.co/blog/recover-a-blacklisted-domain"
                                target="_blank"
                                rel="noreferrer"
                              >
                                Learn more
                              </a>{' '}
                              about how to resolve them.
                            </p>
                          ) : (
                            <p>
                              Everything looks good, your website is clear of any blacklists.
                            </p>
                          )}
                        </div>
                      )}

                      {(() => {
                        const blacklists = securityData.blacklist.blacklists;
                        const itemsPerPage = 50;
                        const totalItems = blacklists.length;
                        const totalPages = Math.ceil(totalItems / itemsPerPage);

                        // Create an array of pages [1..totalPages]
                        const pagesArray = Array.from({ length: totalPages }, (_, i) => i + 1);

                        // Slice items for current page
                        const startIndex = (blacklistPage - 1) * itemsPerPage;
                        const endIndex = startIndex + itemsPerPage;
                        const currentItems = blacklists.slice(startIndex, endIndex);

                        return (
                          <div className="mt-2">
                            {/* Blacklist Table */}
                            <div className="max-h-64 overflow-auto ">
                              <table className="min-w-full bg-white dark:bg-slate-700 rounded-lg">
                                <thead>
                                  <tr className="bg-slate-50 dark:bg-slate-600 text-sm border-b border-slate-100 dark:border-slate-600 text-slate-500 dark:text-slate-300 uppercase">
                                    <th className="py-5 pl-10 font-normal text-left">Name</th>
                                    <th className="py-5 pl-10 font-normal text-center">Clean</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {currentItems.map((item, i) => (
                                    <tr
                                      key={i}
                                      className="border-y border-slate-100 first:border-none last:border-none dark:border-slate-600 dark:hover:bg-slate-500 hover:bg-yellow-50"
                                    >
                                      <td className="py-5 pl-10 text-left dark:text-white">
                                        {item.name || 'N/A'}
                                      </td>
                                      <td className="py-5 pl-10 flex justify-center">
                                        {item.detected ? (
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24"
                                            width={20}
                                            height={20}
                                            fill="none"
                                            stroke="currentColor"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            className="text-red-500"
                                          >
                                            <path d="M15 9L9 14.9996M15 15L9 9.00039" />
                                            <path d="M2.5 12C2.5 7.52166 2.5 5.28249 3.89124 3.89124C5.28249 2.5 7.52166 2.5 12 2.5C16.4783 2.5 18.7175 2.5 20.1088 3.89124C21.5 5.28249 21.5 7.52166 21.5 12C21.5 16.4783 21.5 18.7175 20.1088 20.1088C18.7175 21.5 16.4783 21.5 12 21.5C7.52166 21.5 5.28249 21.5 3.89124 20.1088C2.5 18.7175 2.5 16.4783 2.5 12Z" />
                                          </svg>
                                        ) : (
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24"
                                            width={20}
                                            height={20}
                                            fill="none"
                                            stroke="currentColor"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            className="text-green-500"
                                          >
                                            <path d="M2.5 12C2.5 7.52166 2.5 5.28249 3.89124 3.89124C5.28249 2.5 7.52166 2.5 12 2.5C16.4783 2.5 18.7175 2.5 20.1088 3.89124C21.5 5.28249 21.5 7.52166 21.5 12C21.5 16.4783 21.5 18.7175 20.1088 20.1088C18.7175 21.5 16.4783 21.5 12 21.5C7.52166 21.5 5.28249 21.5 3.89124 20.1088C2.5 18.7175 2.5 16.4783 2.5 12Z" />
                                            <path d="M8 12.5L10.5 15L16 9" />
                                          </svg>
                                        )}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>

                            {/* Numeric Pagination Controls */}
                            <div className="flex flex-wrap items-center justify-center gap-2 mt-5">
                              {pagesArray.map((pageNum) => (
                                <button
                                  key={pageNum}
                                  onClick={() => setBlacklistPage(pageNum)}
                                  className={`px-4 py-2 rounded ${
                                    pageNum === blacklistPage
                                      ? 'bg-yellow-500 text-white'
                                      : 'bg-slate-100 dark:bg-slate-600 dark:text-slate-400 text-slate-600'
                                  }`}
                                >
                                  {pageNum}
                                </button>
                              ))}
                            </div>
                          </div>
                        );
                      })()}
                    </>
                  ) : (
                    <p className="text-slate-600 dark:text-slate-200 mt-2">
                      No blacklist data found.
                    </p>
                  )}
                </div>
              </>
            ) : (
              <p className="text-slate-600 dark:text-slate-200">
                Security data not available.
              </p>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Dashboard;
